import _leaflet from "leaflet";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
	Leaflet.contextmenu, a context menu for Leaflet.
	(c) 2015, Adam Ratcliffe, GeoSmart Maps Limited

	@preserve
*/
(function (factory) {
  // Packaging/modules magic dance
  var L;
  {
    // Node/CommonJS
    L = _leaflet;
    exports = factory(L);
  }
})(function (L) {
  L.Map.mergeOptions({
    contextmenuItems: []
  });
  L.Map.ContextMenu = L.Handler.extend({
    _touchstart: L.Browser.msPointer ? "MSPointerDown" : L.Browser.pointer ? "pointerdown" : "touchstart",
    statics: {
      BASE_CLS: "leaflet-contextmenu"
    },
    initialize: function (map) {
      L.Handler.prototype.initialize.call(this || _global, map);
      (this || _global)._items = [];
      (this || _global)._visible = false;
      var container = (this || _global)._container = L.DomUtil.create("div", L.Map.ContextMenu.BASE_CLS, map._container);
      container.style.zIndex = 10000;
      container.style.position = "absolute";

      if (map.options.contextmenuWidth) {
        container.style.width = map.options.contextmenuWidth + "px";
      }

      this._createItems();

      L.DomEvent.on(container, "click", L.DomEvent.stop).on(container, "mousedown", L.DomEvent.stop).on(container, "dblclick", L.DomEvent.stop).on(container, "contextmenu", L.DomEvent.stop);
    },
    addHooks: function () {
      var container = (this || _global)._map.getContainer();

      L.DomEvent.on(container, "mouseleave", (this || _global)._hide, this || _global).on(document, "keydown", (this || _global)._onKeyDown, this || _global);

      if (L.Browser.touch) {
        L.DomEvent.on(document, (this || _global)._touchstart, (this || _global)._hide, this || _global);
      }

      (this || _global)._map.on({
        contextmenu: (this || _global)._show,
        mousedown: (this || _global)._hide,
        movestart: (this || _global)._hide,
        zoomstart: (this || _global)._hide
      }, this || _global);
    },
    removeHooks: function () {
      var container = (this || _global)._map.getContainer();

      L.DomEvent.off(container, "mouseleave", (this || _global)._hide, this || _global).off(document, "keydown", (this || _global)._onKeyDown, this || _global);

      if (L.Browser.touch) {
        L.DomEvent.off(document, (this || _global)._touchstart, (this || _global)._hide, this || _global);
      }

      (this || _global)._map.off({
        contextmenu: (this || _global)._show,
        mousedown: (this || _global)._hide,
        movestart: (this || _global)._hide,
        zoomstart: (this || _global)._hide
      }, this || _global);
    },
    showAt: function (point, data) {
      if (point instanceof L.LatLng) {
        point = (this || _global)._map.latLngToContainerPoint(point);
      }

      this._showAtPoint(point, data);
    },
    hide: function () {
      this._hide();
    },
    addItem: function (options) {
      return this.insertItem(options);
    },
    insertItem: function (options, index) {
      index = index !== undefined ? index : (this || _global)._items.length;

      var item = this._createItem((this || _global)._container, options, index);

      (this || _global)._items.push(item);

      (this || _global)._sizeChanged = true;

      (this || _global)._map.fire("contextmenu.additem", {
        contextmenu: this || _global,
        el: item.el,
        index: index
      });

      return item.el;
    },
    removeItem: function (item) {
      var container = (this || _global)._container;

      if (!isNaN(item)) {
        item = container.children[item];
      }

      if (item) {
        this._removeItem(L.Util.stamp(item));

        (this || _global)._sizeChanged = true;

        (this || _global)._map.fire("contextmenu.removeitem", {
          contextmenu: this || _global,
          el: item
        });

        return item;
      }

      return null;
    },
    removeAllItems: function () {
      var items = (this || _global)._container.children,
          item;

      while (items.length) {
        item = items[0];

        this._removeItem(L.Util.stamp(item));
      }

      return items;
    },
    hideAllItems: function () {
      var item, i, l;

      for (i = 0, l = (this || _global)._items.length; i < l; i++) {
        item = (this || _global)._items[i];
        item.el.style.display = "none";
      }
    },
    showAllItems: function () {
      var item, i, l;

      for (i = 0, l = (this || _global)._items.length; i < l; i++) {
        item = (this || _global)._items[i];
        item.el.style.display = "";
      }
    },
    setDisabled: function (item, disabled) {
      var container = (this || _global)._container,
          itemCls = L.Map.ContextMenu.BASE_CLS + "-item";

      if (!isNaN(item)) {
        item = container.children[item];
      }

      if (item && L.DomUtil.hasClass(item, itemCls)) {
        if (disabled) {
          L.DomUtil.addClass(item, itemCls + "-disabled");

          (this || _global)._map.fire("contextmenu.disableitem", {
            contextmenu: this || _global,
            el: item
          });
        } else {
          L.DomUtil.removeClass(item, itemCls + "-disabled");

          (this || _global)._map.fire("contextmenu.enableitem", {
            contextmenu: this || _global,
            el: item
          });
        }
      }
    },
    isVisible: function () {
      return (this || _global)._visible;
    },
    _createItems: function () {
      var itemOptions = (this || _global)._map.options.contextmenuItems,
          item,
          i,
          l;

      for (i = 0, l = itemOptions.length; i < l; i++) {
        (this || _global)._items.push(this._createItem((this || _global)._container, itemOptions[i]));
      }
    },
    _createItem: function (container, options, index) {
      if (options.separator || options === "-") {
        return this._createSeparator(container, index);
      }

      var itemCls = L.Map.ContextMenu.BASE_CLS + "-item",
          cls = options.disabled ? itemCls + " " + itemCls + "-disabled" : itemCls,
          el = this._insertElementAt("a", cls, container, index),
          callback = this._createEventHandler(el, options.callback, options.context, options.hideOnSelect),
          icon = this._getIcon(options),
          iconCls = this._getIconCls(options),
          html = "";

      if (icon) {
        html = "<img class=\"" + L.Map.ContextMenu.BASE_CLS + "-icon\" src=\"" + icon + "\"/>";
      } else if (iconCls) {
        html = "<span class=\"" + L.Map.ContextMenu.BASE_CLS + "-icon " + iconCls + "\"></span>";
      }

      el.innerHTML = html + options.text;
      el.href = "#";
      L.DomEvent.on(el, "mouseover", (this || _global)._onItemMouseOver, this || _global).on(el, "mouseout", (this || _global)._onItemMouseOut, this || _global).on(el, "mousedown", L.DomEvent.stopPropagation).on(el, "click", callback);

      if (L.Browser.touch) {
        L.DomEvent.on(el, (this || _global)._touchstart, L.DomEvent.stopPropagation);
      } // Devices without a mouse fire "mouseover" on tap, but never “mouseout"


      if (!L.Browser.pointer) {
        L.DomEvent.on(el, "click", (this || _global)._onItemMouseOut, this || _global);
      }

      return {
        id: L.Util.stamp(el),
        el: el,
        callback: callback
      };
    },
    _removeItem: function (id) {
      var item, el, i, l, callback;

      for (i = 0, l = (this || _global)._items.length; i < l; i++) {
        item = (this || _global)._items[i];

        if (item.id === id) {
          el = item.el;
          callback = item.callback;

          if (callback) {
            L.DomEvent.off(el, "mouseover", (this || _global)._onItemMouseOver, this || _global).off(el, "mouseover", (this || _global)._onItemMouseOut, this || _global).off(el, "mousedown", L.DomEvent.stopPropagation).off(el, "click", callback);

            if (L.Browser.touch) {
              L.DomEvent.off(el, (this || _global)._touchstart, L.DomEvent.stopPropagation);
            }

            if (!L.Browser.pointer) {
              L.DomEvent.on(el, "click", (this || _global)._onItemMouseOut, this || _global);
            }
          }

          (this || _global)._container.removeChild(el);

          (this || _global)._items.splice(i, 1);

          return item;
        }
      }

      return null;
    },
    _createSeparator: function (container, index) {
      var el = this._insertElementAt("div", L.Map.ContextMenu.BASE_CLS + "-separator", container, index);

      return {
        id: L.Util.stamp(el),
        el: el
      };
    },
    _createEventHandler: function (el, func, context, hideOnSelect) {
      var me = this || _global,
          map = (this || _global)._map,
          disabledCls = L.Map.ContextMenu.BASE_CLS + "-item-disabled",
          hideOnSelect = hideOnSelect !== undefined ? hideOnSelect : true;
      return function (e) {
        if (L.DomUtil.hasClass(el, disabledCls)) {
          return;
        }

        if (hideOnSelect) {
          me._hide();
        }

        if (func) {
          func.call(context || map, me._showLocation);
        }

        me._map.fire("contextmenu.select", {
          contextmenu: me,
          el: el
        });
      };
    },
    _insertElementAt: function (tagName, className, container, index) {
      var refEl,
          el = document.createElement(tagName);
      el.className = className;

      if (index !== undefined) {
        refEl = container.children[index];
      }

      if (refEl) {
        container.insertBefore(el, refEl);
      } else {
        container.appendChild(el);
      }

      return el;
    },
    _show: function (e) {
      this._showAtPoint(e.containerPoint, e);
    },
    _showAtPoint: function (pt, data) {
      if ((this || _global)._items.length) {
        var map = (this || _global)._map,
            layerPoint = map.containerPointToLayerPoint(pt),
            latlng = map.layerPointToLatLng(layerPoint),
            event = L.extend(data || {}, {
          contextmenu: this || _global
        });
        (this || _global)._showLocation = {
          latlng: latlng,
          layerPoint: layerPoint,
          containerPoint: pt
        };

        if (data && data.relatedTarget) {
          (this || _global)._showLocation.relatedTarget = data.relatedTarget;
        }

        this._setPosition(pt);

        if (!(this || _global)._visible) {
          (this || _global)._container.style.display = "block";
          (this || _global)._visible = true;
        }

        (this || _global)._map.fire("contextmenu.show", event);
      }
    },
    _hide: function () {
      if ((this || _global)._visible) {
        (this || _global)._visible = false;
        (this || _global)._container.style.display = "none";

        (this || _global)._map.fire("contextmenu.hide", {
          contextmenu: this || _global
        });
      }
    },
    _getIcon: function (options) {
      return L.Browser.retina && options.retinaIcon || options.icon;
    },
    _getIconCls: function (options) {
      return L.Browser.retina && options.retinaIconCls || options.iconCls;
    },
    _setPosition: function (pt) {
      var mapSize = (this || _global)._map.getSize(),
          container = (this || _global)._container,
          containerSize = this._getElementSize(container),
          anchor;

      if ((this || _global)._map.options.contextmenuAnchor) {
        anchor = L.point((this || _global)._map.options.contextmenuAnchor);
        pt = pt.add(anchor);
      }

      container._leaflet_pos = pt;

      if (pt.x + containerSize.x > mapSize.x) {
        container.style.left = "auto";
        container.style.right = Math.min(Math.max(mapSize.x - pt.x, 0), mapSize.x - containerSize.x - 1) + "px";
      } else {
        container.style.left = Math.max(pt.x, 0) + "px";
        container.style.right = "auto";
      }

      if (pt.y + containerSize.y > mapSize.y) {
        container.style.top = "auto";
        container.style.bottom = Math.min(Math.max(mapSize.y - pt.y, 0), mapSize.y - containerSize.y - 1) + "px";
      } else {
        container.style.top = Math.max(pt.y, 0) + "px";
        container.style.bottom = "auto";
      }
    },
    _getElementSize: function (el) {
      var size = (this || _global)._size,
          initialDisplay = el.style.display;

      if (!size || (this || _global)._sizeChanged) {
        size = {};
        el.style.left = "-999999px";
        el.style.right = "auto";
        el.style.display = "block";
        size.x = el.offsetWidth;
        size.y = el.offsetHeight;
        el.style.left = "auto";
        el.style.display = initialDisplay;
        (this || _global)._sizeChanged = false;
      }

      return size;
    },
    _onKeyDown: function (e) {
      var key = e.keyCode; // If ESC pressed and context menu is visible hide it

      if (key === 27) {
        this._hide();
      }
    },
    _onItemMouseOver: function (e) {
      L.DomUtil.addClass(e.target || e.srcElement, "over");
    },
    _onItemMouseOut: function (e) {
      L.DomUtil.removeClass(e.target || e.srcElement, "over");
    }
  });
  L.Map.addInitHook("addHandler", "contextmenu", L.Map.ContextMenu);
  L.Mixin.ContextMenu = {
    bindContextMenu: function (options) {
      L.setOptions(this || _global, options);

      this._initContextMenu();

      return this || _global;
    },
    unbindContextMenu: function () {
      this.off("contextmenu", (this || _global)._showContextMenu, this || _global);
      return this || _global;
    },
    addContextMenuItem: function (item) {
      (this || _global).options.contextmenuItems.push(item);
    },
    removeContextMenuItemWithIndex: function (index) {
      var items = [];

      for (var i = 0; i < (this || _global).options.contextmenuItems.length; i++) {
        if ((this || _global).options.contextmenuItems[i].index == index) {
          items.push(i);
        }
      }

      var elem = items.pop();

      while (elem !== undefined) {
        (this || _global).options.contextmenuItems.splice(elem, 1);

        elem = items.pop();
      }
    },
    replaceContextMenuItem: function (item) {
      this.removeContextMenuItemWithIndex(item.index);
      this.addContextMenuItem(item);
    },
    _initContextMenu: function () {
      (this || _global)._items = [];
      this.on("contextmenu", (this || _global)._showContextMenu, this || _global);
    },
    _showContextMenu: function (e) {
      var itemOptions, data, pt, i, l;

      if ((this || _global)._map.contextmenu) {
        data = L.extend({
          relatedTarget: this || _global
        }, e);
        pt = (this || _global)._map.mouseEventToContainerPoint(e.originalEvent);

        if (!(this || _global).options.contextmenuInheritItems) {
          (this || _global)._map.contextmenu.hideAllItems();
        }

        for (i = 0, l = (this || _global).options.contextmenuItems.length; i < l; i++) {
          itemOptions = (this || _global).options.contextmenuItems[i];

          (this || _global)._items.push((this || _global)._map.contextmenu.insertItem(itemOptions, itemOptions.index));
        }

        (this || _global)._map.once("contextmenu.hide", (this || _global)._hideContextMenu, this || _global);

        (this || _global)._map.contextmenu.showAt(pt, data);
      }
    },
    _hideContextMenu: function () {
      var i, l;

      for (i = 0, l = (this || _global)._items.length; i < l; i++) {
        (this || _global)._map.contextmenu.removeItem((this || _global)._items[i]);
      }

      (this || _global)._items.length = 0;

      if (!(this || _global).options.contextmenuInheritItems) {
        (this || _global)._map.contextmenu.showAllItems();
      }
    }
  };
  var classes = [L.Marker, L.Path],
      defaultOptions = {
    contextmenu: false,
    contextmenuItems: [],
    contextmenuInheritItems: true
  },
      cls,
      i,
      l;

  for (i = 0, l = classes.length; i < l; i++) {
    cls = classes[i]; // L.Class should probably provide an empty options hash, as it does not test
    // for it here and add if needed

    if (!cls.prototype.options) {
      cls.prototype.options = defaultOptions;
    } else {
      cls.mergeOptions(defaultOptions);
    }

    cls.addInitHook(function () {
      if ((this || _global).options.contextmenu) {
        this._initContextMenu();
      }
    });
    cls.include(L.Mixin.ContextMenu);
  }

  return L.Map.ContextMenu;
});

export default exports;